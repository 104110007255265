 export default {
   tooltip: {
     trigger: 'item'
   },
   legend: {
     bottom: '5%',
     left: 'center',
     // doesn't perfectly work with our tricks, disable it
     selectedMode: false
   },
   series: [{
     type: 'pie',
     radius: ['60%', '80%'],
     center: ['50%', '65%'],
     // adjust the start angle
     startAngle: 180,
     label: {
       show: true,
       formatter (param) {
         // correct the percentage
         return param.name + ' (' + param.percent * 2 + '%)'
       }
     },
    //  itemStyle: {
    //    borderRadius: [5, 5, 5, 5],
    //    borderColor: '#fff',
    //    borderWidth: 2
    //  },
    data: []
  //    data: [{
  //        value: 1048,
  //        name: 'Search Engine'
  //      },
  //      {
  //        value: 735,
  //        name: 'Direct'
  //      },
  //      {
  //        value: 580,
  //        name: 'Email'
  //      },
  //      {
  //        value: 484,
  //        name: 'Union Ads'
  //      },
  //      {
  //        value: 300,
  //        name: 'Video Ads'
  //      },
  //      {
  //        value: 300,
  //        name: 'test1'
  //      },
  //      {
  //        value: 300,
  //        name: 'test2'
  //      },
  //      {
  //        value: 300,
  //        name: 'test3'
  //      },
  //      {
  //        value: 300,
  //        name: 'test4'
  //      },
  //      {
  //        value: 300,
  //        name: 'test5'
  //      },
  //      {
  //        // make an record to fill the bottom 50%
  //        value: 1048 + 735 + 580 + 484 + 300 * 6,
  //        itemStyle: {
  //          // stop the chart from rendering this piece
  //          color: 'none',
  //          decal: {
  //            symbol: 'none'
  //          }
  //        },
  //        label: {
  //          show: false
  //        }
  //      }
  //    ]
   }]
 }
